.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.waves-effect .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    background: -webkit-radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
    background: -o-radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
    background: -moz-radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
    -webkit-transform: scale(0) translate(0, 0);
    -moz-transform: scale(0) translate(0, 0);
    -ms-transform: scale(0) translate(0, 0);
    -o-transform: scale(0) translate(0, 0);
    transform: scale(0) translate(0, 0);
    pointer-events: none;
}
.waves-effect.waves-light .waves-ripple {
    background: rgba(255, 255, 255, 0.4);
    background: -webkit-radial-gradient(rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
    background: -o-radial-gradient(rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
    background: -moz-radial-gradient(rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
}
.waves-effect.waves-classic .waves-ripple {
    background: rgba(0, 0, 0, 0.2);
}
.waves-effect.waves-classic.waves-light .waves-ripple {
    background: rgba(255, 255, 255, 0.4);
}

.waves-notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.waves-button,
.waves-circle {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.waves-button,
.waves-button:hover,
.waves-button:visited,
.waves-button-input {
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    outline: none;
    color: inherit;
    background-color: rgba(0, 0, 0, 0);
    font-size: 1em;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    z-index: 1;
}

.waves-button {
    padding: 0.85em 1.1em;
    border-radius: 0.2em;
}

.waves-button-input {
    margin: 0;
    padding: 0.85em 1.1em;
}

.waves-input-wrapper {
    border-radius: 0.2em;
    vertical-align: bottom;
}
.waves-input-wrapper.waves-button {
    padding: 0;
}
.waves-input-wrapper .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
}

.waves-circle {
    text-align: center;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 50%;
}

.waves-float {
    -webkit-mask-image: none;
    -webkit-box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}
.waves-float:active {
    -webkit-box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3);
}

.waves-block {
    display: block;
}
